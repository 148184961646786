<template>
    <div style="position:relative;">
        <input type="hidden" :name="monthInputName" :value="expiryMonth" data-target="#p3-mask-expiry-date">
        <input type="hidden" :name="yearInputName" :value="expiryYear" data-target="#p3-mask-expiry-date">
        <div class="p3-mask" v-html="maskDate" ></div>
        <input id="expiryDate" aria-describedby="expireYear-error" class="form-control p3-expiry-card" :class="getInputClasses()" type="tel" @keyup="placeDivider($event)" v-model="date" autocomplete="cc-exp">
    </div>
</template>

<script>
import { isNumeric } from '../inc/helpers';
import spacetime from "spacetime";

export default {
    name: 'expiry-card',
    data() {
        return {
            expiryMonth: '',
            expiryYear: '',
            date: '',
            maskDate: 'MM/YY',
            dateHasBeenEntered: false,
            dividerPlaced: false
        }
    },
    props: {
        month: [String, Number],
        year: [String, Number],
        monthInputName: {
            type: String,
            default: function () {
                return 'expireMonth';
            }
        },
        yearInputName: {
            type: String,
            default: function () {
                return 'expireYear';
            }
        }
    },
    computed: {
        placeholderFormat() {
            return 'MM/YY';
        },
        divider() {
            return '/';
        },

    },
    methods: {
        placeDivider(event) {
            const shouldPlaceDivider = (
                this.date.length >= 2 &&
                ! this.dividerPlaced
            );
            if (isNumeric(event.key) && shouldPlaceDivider) {
                this.date = this.date.slice(0, 2) + this.divider + this.date.slice(2, 4);
                this.dividerPlaced = true;
            }
        },
        isValidMonth(month) {
            return isNumeric(month) && month >= 0 && month <= 12;
        },
        /**
         * Autofill the expiry date if the props "year" and "month" are passed
         */
        setProps() {
            if (this.isValidExpiryDate(this.year, this.month)) {
                this.date = this.month + this.divider + this.year.slice(2, 4);
            }
        },
        isValidExpiryDate(expiryYear, expiryMonth) {
            const explodedDate = [expiryYear, expiryMonth, '01'];
            return spacetime(explodedDate.join('-')).isAfter(spacetime.now())
        },
        isValid() {
            return (
                this.date.length == 5 &&
                this.isValidExpiryDate(this.expiryYear, this.expiryMonth)
            );
        },
        getInputClasses() {
            if (! this.dateHasBeenEntered) {
                return {};
            }
            return {
                'validationError': ! this.isValid(),
                'valid': this.isValid()
            };
        }
    },
    watch: {
        date(newValue, oldValue) {
            this.dividerPlaced = this.date.length > 2;
            /**
             * If the month entered is greater than 1 autofill prepend a 0
             * e.g enters 9 and becomes "09/"
             */
            if (newValue.length == 1 && newValue > 1) {
                this.date = '0' + newValue;
                return;
            }

            /**
             * If the month entered is 1 followed by a "/" autofill prepend a 0
             * e.g enters "1" followed by "/", becomes "01/"
             */
            if (this.date.length == 2 && oldValue == 1 && newValue.includes(this.divider)) {
                this.date = '0' + this.date;
                this.dividerPlaced = true;
                return;
            }

            /**
             * We want to be able to handle whether the user enters the / between the MM and YY on the card or not.
             * We will automatically add the slash for them, but if they attempt to also type the slash in the middle
             * we will simply ignore it
             */
            if (this.date.length == 4 && newValue.endsWith("//")) {
                this.date = newValue.slice(0, -1);
                this.dividerPlaced = true;
                return;
            }

            /**
             * Wrap each entered character in a span so they can be styled differently to the placeholder
             */
            let maskValue = '';
            this.placeholderFormat.split('').forEach((char, index) => {
                if (this.date[index]) {
                    maskValue += `<span>${this.date[index]}</span>`;
                } else {
                    maskValue += char;
                }
            });
            this.maskDate = maskValue;

            // only allow months from 1 to 12
            if (newValue.length >= 2 && ! this.isValidMonth(newValue.slice(0, 2))) {
                this.date = oldValue;
                return;
            }

            // prevent the date from being longer than 5 characters includes the divider /
            if (newValue.length > 5) {
                this.date = oldValue;
                return;
            }

            /**
             * if they date has been entered fill in the month and year
             * otherwise set them as null
             */
            if (newValue.length == 5) {
                this.dateHasBeenEntered = true;
                const dateInfo = newValue.split(this.divider);
                this.expiryMonth = dateInfo[0];
                this.expiryYear = '20' + dateInfo[1]; // format year to YYYY
            } else {
                this.expiryMonth = '';
                this.expiryYear = '';
            }
        }
    },
    mounted() {
        this.setProps();
    }
}
</script>

<style>
    .p3-expiry-card {
        color: transparent;
        caret-color: #333;
    }
    .p3-mask {
        position: absolute;
        left: 13px;
        right: 13px;
        top: 7px;
        bottom: 7px;
        color: #ccc;
        pointer-events: none;
    }
    .p3-mask span {
        color: #333;
    }
</style>
