<template>
    <div class="datepicker-trigger" tabindex="-1">
        <label v-show="isArrivalFocused" for="arrivalDateFocus">Select your arrival</label>
        <input type="date" @keydown.enter.prevent="" class="sr-only visually-hidden sr-only-focusable visually-hidden-focusable" v-model="arrival"  id="arrivalDateFocus" @focus="isArrivalFocused = true" @blur="isArrivalFocused = false">
        <label v-show="isDepartureFocused" for="departureDateFocus">Select your departure</label>
        <input v-if="isRangeMode" type="date" @keydown.enter.prevent="" class="sr-only visually-hidden sr-only-focusable visually-hidden-focusable" v-model="departure" id="departureDateFocus" @focus="isDepartureFocused = true" @blur="isDepartureFocused = false">
        <input class="form-control" type="text" id="trigger" :value="dateDisplay(arrival, departure)" readonly aria-label="Your selected date" tabindex="-1">
        <input type="hidden" name="arrival" v-model="arrival">
        <input type="hidden" v-if="isRangeMode" name="departure" v-model="departure">
        <airbnb-style-datepicker
            :trigger-element-id="'trigger'"
            :mode="config.datepickerConfig.mode"
            :fullscreen-mobile="config.datepickerConfig.fullscreenMobile"
            :date-one="arrival"
            :date-two="departure"
            :mobileHeader="config.datepickerConfig.mobileHeader"
            :minDate="minDate"
            :endDate="maxDate"
            :showActionButtons="!config.datepickerConfig.hideActionButtons"
            :showShortcutsMenuTrigger="false"
            :closeAfterSelect="config.datepickerConfig.closeAfterSelect"
            :monthsToShow="monthsToShow"
            @date-one-selected="onDateOneSelected"
            @date-two-selected="onDateTwoSelected"
            @closed="onClosedMethod"
        ></airbnb-style-datepicker>
    </div>
</template>

<script>
    /**
     * for some reason AirbnbStyleDatepicker requires a date formatting package that is supposedly optional
     * but if you import it, it craps out
     * So have to include it before this js file and then take it from the window
     */
    window.Vue.use(vueAirbnbStyleDatepicker, window.searchWidget ? window.searchWidget.datepickerOptions : {});
    import spacetime from 'spacetime';

    export default {
        name: "datepicker",
        props: {
            config: {
                required: true,
                type: Object
            },
        },
        data() {
            const { datepickerConfig } = this.config;
            return {
                arrival: datepickerConfig.arrival,
                departure: datepickerConfig.departure,
                dateDisplay: datepickerConfig.dateDisplay,
                monthsToShow: datepickerConfig.monthsToShow,
                isArrivalFocused: false,
                isDepartureFocused: false
            }
        },
        computed: {
            isRangeMode() {
              return this.config.datepickerConfig.mode == 'range';
            },
            minDate() {
                const today = spacetime(this.config.datepickerConfig.minDate).subtract(1, 'day');
                return today.toNativeDate();
            },
            maxDate() {
                const today = new Date();
                return new Date(today.setDate(today.getDate() + this.config.datepickerConfig.maxDays));
            }
        },
        methods: {
            onDateOneSelected(val) {
                this.arrival = val;
                this.emitDateChange();
            },
            onDateTwoSelected(val) {
                const spacetimeArrivalDate = spacetime(this.arrival);
                const spacetimeDepartureDate = spacetime(val);
                const numNights = spacetimeArrivalDate.diff(spacetimeDepartureDate, 'day');
                if (this.isRangeMode && (! val || numNights <= 0)) {
                    const spacetimeArrivalDate = spacetime(this.arrival);
                    const spacetimeDepartureDate = spacetimeArrivalDate.add(1, 'day');
                    this.departure = spacetimeDepartureDate.format('iso-short');
                } else {
                    this.departure = val;
                }
                this.emitDateChange();
            },
            emitDateChange() {
                this.$emit('change', {
                    arrival: this.arrival,
                    departure: this.departure
                });
            },
            onClosedMethod: function() {
                if (this.isRangeMode) {
                    if (this.departure == this.arrival ) {
                        const spacetimeArrivalDate = spacetime(this.arrival);
                        const spacetimeDepartureDate = spacetimeArrivalDate.add(1, 'day');
                        this.departure = spacetimeDepartureDate.format('iso-short');
                    }
                }
            }
        }
    }
</script>
